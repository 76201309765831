import React from 'react';
import cx from 'classnames';

interface Props {
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
  name: string;
  required?: boolean;
  id: string;
  type?: string;
  placeholder?: string;
  error?: string | boolean;
  className?: string;
  disabled?: boolean;
}

const FormText: React.FC<Props> = (props: Props) => {
  function validateNumber(evt: any) {
    if (props.type === 'number') {
      const e = evt || window.event;
      const key = e.keyCode || e.which;

      console.log('zdf', key, typeof key);

      if (
        (!e.shiftKey &&
          !e.altKey &&
          !e.ctrlKey &&
          // numbers
          key >= 48 &&
          key <= 57) ||
        // Numeric keypad
        (key >= 96 && key <= 105) ||
        // Backspace and Tab and Enter
        key === 8 ||
        key === 9 ||
        key === 13 ||
        // Home and End
        key === 35 ||
        key === 36 ||
        // left and right arrows
        key === 37 ||
        key === 39 ||
        // Del and Ins
        key === 46 ||
        key === 45
      ) {
        // input is VALID
      } else {
        // input is INVALID
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
    }
  }

  return (
    <div className={cx('w-full', props.className ?? '')}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={cx(
            'font-semibold mb-1 text-base',
            props.required && 'flex items-start'
          )}
        >
          {props.label}
          {props.required && (
            <span className="ml-0.5 text-base font-semibold" aria-hidden>
              *
            </span>
          )}
        </label>
      )}
      <input
        className={cx(
          'py-2.5 px-4 bg-white rounded placeholder-gray-300 font-normal text-lg w-full outline-none border border-solid border-primary',
          props.label && 'hover:border-primaryDark focus:border-primaryDark',
          props.error && 'border-error',
          props.disabled &&
            'override:bg-gray-300 cursor-not-allowed override:border-gray-600 override:hover:border-gray-600'
        )}
        id={props.id}
        type={props.type ?? 'text'}
        onChange={props.onChange}
        disabled={props.disabled}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        onKeyDown={validateNumber}
      />
    </div>
  );
};

export default FormText;
