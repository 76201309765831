import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Logo from '../assets/logo-app.png';
import FormText from '../components/form-text';
import FWButton from '../components/fw-button';
import { ApiService } from '../services/ApiService';
import AppWrapper from '../components/app-wrapper';
import { authService } from '../services/AuthService';
import ApiRoutes from '../routes/api/ApiRoutes';

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Email Address is required'),
  password: Yup.string().required('Password is required'),
});

const LoginPage = () => {
  const history = useHistory();

  const loginHandler = async (values: any) => {
    try {
      const response = await ApiService.getInstance().post(
        ApiRoutes.auth.login,
        {
          email: values.email,
          password: values.password,
        }
      );
      authService.setAuthToken(response.token);
      history.replace('/home');
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  return (
    <AppWrapper>
      <main className="w-full min-h-full flex flex-col items-center bg-lightGrey text-center py-10 px-5">
        <div className="w-20 h-20 mb-4">
          <img
            src={Logo}
            alt="Logo"
            className="w-full h-full object-cover object-center"
          />
        </div>
        <h1 className="text-3xl font-medium mb-5">Sign in to your account</h1>
        <div className="w-full sm:w-96 rounded-lg bg-white p-5 md:py-10 md:px-5 flex flex-col shadow">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={loginSchema}
            onSubmit={async values => {
              await loginHandler(values);
            }}
          >
            {formikProps => {
              return (
                <form
                  autoComplete="off"
                  onSubmit={formikProps.handleSubmit}
                  onReset={formikProps.handleReset}
                >
                  <FormText
                    label="Email"
                    required
                    id="email"
                    name="email"
                    placeholder="Please enter your email"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.email && formikProps.errors.email
                    }
                    className="mb-5"
                    disabled={formikProps.isSubmitting}
                  />
                  <FormText
                    label="Password"
                    required
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Please enter your password"
                    value={formikProps.values.password}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.touched.password &&
                      formikProps.errors.password
                    }
                    className="mb-5"
                    disabled={formikProps.isSubmitting}
                  />
                  <FWButton
                    type="submit"
                    className="w-full"
                    disabled={formikProps.isSubmitting}
                  >
                    Sign In
                  </FWButton>
                </form>
              );
            }}
          </Formik>
        </div>
      </main>
    </AppWrapper>
  );
};

export default LoginPage;
