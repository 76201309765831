import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import AppWrapper from '../components/app-wrapper';

const SplashPage = () => {
  const history = useHistory();

  useEffect(() => {
    const loggedIn = localStorage.getItem('auth_token');
    if (loggedIn) {
      history.push('/home');
      return;
    }
    history.push('/login');
  }, [history]);

  return (
    <AppWrapper className="flex justify-center items-center">
      <CircularProgress />
    </AppWrapper>
  );
};

export default SplashPage;
