import React from 'react';
import { ToastContainer } from 'react-toastify';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const AppWrapper: React.FC<Props> = (props: Props) => {
  return (
    <div className={cx('w-screen h-screen', props.className ?? '')}>
      {props.children}
      <ToastContainer />
    </div>
  );
};

export default AppWrapper;
