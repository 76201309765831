import React from 'react';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: Function;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
}

const FWButton: React.FC<Props> = (props: Props) => {
  return (
    <button
      className={cx(
        'rounded bg-primary text-white py-2.5 px-5 text-lg hover:bg-primaryDark active:bg-primaryDark disabled:bg-primaryDark disabled:cursor-not-allowed font-semibold disabled:shadow-none',
        props.className ?? ''
      )}
      type={props.type}
      disabled={props.disabled}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.children}
    </button>
  );
};

FWButton.defaultProps = {
  type: 'button',
};

export default FWButton;
