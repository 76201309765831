import React from 'react';
import { Redirect, Route } from 'react-router';

const ProtectedRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const loggedIn = localStorage.getItem('auth_token');

  if (!loggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: rest.location },
        }}
      />
    );
  }

  return <Route {...rest} render={(props: any) => <Component {...props} />} />;
};

export default ProtectedRoute;
