export default {
  auth: {
    me: '/me',
    login: '/auth/login',
  },
  demo: {
    moneyMap: 'demo/mx-money-map',
    finstrong: 'demo/mx-finstrong',
  },
};
