import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import SplashPage from './pages/SplashPage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './guards/ProtectedRoute';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <ProtectedRoute path="/home">
          <LandingPage />
        </ProtectedRoute>
        <Route path="/">
          <SplashPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
