import { times, random } from 'lodash';

export class Utils {
  static getRandomString = (length = 20) =>
    times(length, () => random(35).toString(36)).join('');

  static makeFirstLetterCapital = (str: string): string =>
    str.charAt(0).toUpperCase() + str.substr(1);

  static removeUnderscore = (str: string): string => {
    const list = str.split('_');
    return `${Utils.makeFirstLetterCapital(list[0])} ${list
      .slice(1)
      .join(' ')}`;
  };
}
