import React from 'react';
import Error from '../assets/error.png';

const NoDataFound: React.FC = () => {
  return (
    <div className="h-full w-7/12 flex flex-col items-center justify-center">
      <img src={Error} alt="Logo" className="w-1/4 h-1/4 object-contain" />
      <h3 className="mt-8 text-darkText text-4xl font-regular text-center">
        Mx account not connected for this user.
        <br /> Please attach bank account .
      </h3>
    </div>
  );
};

export default NoDataFound;
