import Axios, { CancelTokenSource } from 'axios';
import { Utils } from '../utils/utils';
import { authService } from './AuthService';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export class ApiService {
  private static instance = new ApiService();

  private requestMap = new Map<string, CancelTokenSource>();

  static getInstance() {
    return this.instance;
  }

  get(url: string, params?: any, headers?: any, requestId?: string) {
    return this.request({ method: 'GET', url, headers, params, requestId });
  }

  delete(
    url: string,
    params?: any,
    headers?: any,
    requestId?: string,
    data?: any
  ) {
    return this.request({
      method: 'DELETE',
      url,
      headers,
      params,
      requestId,
      data,
    });
  }

  post(
    url: string,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string
  ) {
    return this.request({
      method: 'POST',
      url,
      data,
      headers,
      params,
      requestId,
    });
  }

  put(
    url: string,
    data?: any,
    useBaseUrl = true,
    useAuthHeaders = true,
    headers?: any,
    params?: any,
    requestId?: string,
    onUploadProgress?: any
  ) {
    return this.request({
      method: 'PUT',
      url,
      data,
      headers,
      params,
      requestId,
      useBaseUrl,
      useAuthHeaders,
      onUploadProgress,
    });
  }

  patch(
    url: string,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string
  ) {
    return this.request({
      method: 'PATCH',
      url,
      data,
      headers,
      params,
      requestId,
    });
  }

  generateHeaders = (additionalHeaders: any, useAuthHeaders: boolean) => {
    const headers = {
      ...additionalHeaders,
    };

    if (useAuthHeaders) {
      headers.Authorization = authService.authToken;
    }
    return { ...headers };
  };

  // TODO: pass token only when required.
  private async request({
    useBaseUrl = true,
    useAuthHeaders = true,
    ...config
  }) {
    const cancelToken = this.addToRequestMap(config.requestId);
    try {
      console.log(BASE_URL, config);
      const response = await Axios.request({
        baseURL: useBaseUrl ? BASE_URL : undefined,
        cancelToken,
        ...config,
        headers: this.generateHeaders(config.headers, useAuthHeaders),
      });
      this.removeFromRequestMap(config.requestId);
      return response?.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  private addToRequestMap(requestId?: string) {
    if (!requestId) {
      return;
    }

    const source = Axios.CancelToken.source();
    this.requestMap.set(requestId!, source);
    return source.token;
  }

  private removeFromRequestMap(requestId?: string) {
    if (!requestId) {
      return;
    }

    this.requestMap.delete(requestId);
  }

  generateRequsetId(): string {
    return Utils.getRandomString();
  }

  cancelRequest(requestId: string) {
    const source = this.requestMap.get(requestId);
    source && source.cancel();
  }
}
